<template>
<div>
  <v-progress-linear :active="loading" color="red" indeterminate rounded height="5"></v-progress-linear>
  <v-card style="padding: 15px">
    <div>
      <h4 class="text-h6 font-weight-medium pb-4">Últimos Artigos</h4>
      <div>
        <v-row v-for="i in artigos" :key="i.Titulo">
          <v-col cols="12" lg="12" @click="irArtigo(i.Id)">
            <div style="margin-left: 10px">
              <h5 class="text-h8 font-weight-bold black--text; " style="cursor: pointer">
                <router-link
                  :to="'/Artigo?id='+ i.Id"
                  style="background-color: #ffaa00; padding: 3px; color: white"
                  >
                    {{i.Titulo}}
                  </router-link>
              </h5>
              <h6 class="color: #ffaa00">{{i.Nome}} em {{dataAtualFormatada(i.DtCriacao)}}</h6>
            </div>
          </v-col>
          <v-divider></v-divider>
        </v-row>
      </div>
    </div>
   </v-card>
</div>


</template>
<script>
import main from '../../assets/scripts/main.js';

export default {
  data: () => ({
    artigos: [],
    loading: true
  }),
  created(){
    this.getArtigos()
  },
  methods: {
      dataAtualFormatada(dataEnviada){
        return main.dataAtualFormatada(dataEnviada)
    },
     async getArtigos() {
        var req = {}
        var resp = await main.postApi(req, "artigos/pegarArtigosRecentes");
        var retorno = await resp.json();

        if(retorno.sucesso){
          this.artigos = retorno.corpo;
          this.loading = false;
          console.log("this.artigos")
          console.log(this.artigos)
        }
      },
      dataUrl(foto){
        return main.dataUrl(foto)
      },
      irArtigo(id){

      }
  }
};
</script>
